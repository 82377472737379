@import "~bootstrap/scss/bootstrap-grid.scss";


.allocatable-entries-form {

    margin: 0 auto;
    position: relative;

    .entries-to-allocate {
        text-align: center;
    }

    .entries-submitted-success {
        width: 70%;
        margin: 0 auto;
        padding: 1em;
        text-align: center;
    }

    .drawing-group {
        margin-bottom: 1em;
        padding: 1em;

        .number-entries-field {

            .numbers-entry-label {
                text-align: center;
                font-size: 0.8em;
            }
        }
    }

    @include media-breakpoint-up(xs) {
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        width: 70%;
    }

    @include media-breakpoint-up(lg) {
        width: 50%;
    }
}