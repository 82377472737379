.optin-category {
    margin: 1rem 0;
}

.optin-select-label {
    display: block;
}

.optin-category-title {
    font-size: 1.3em;
    font-weight: bold;
}