// colors
$white: #fff;
$black: #000;
$theme-green: #16911e;
$theme-light-gray: #cecece;
$theme-medium-gray: #adadad;
$theme-medium-dark-gray: #707070;
$theme-dark-gray: #2d3e4c;
$theme-darkest-gray: #111111;
$theme-purple: #524fa2;
$theme-blue: #065596;
$theme-dark-blue: #023674;
$theme-pink: #ca0a7e;
$theme-yellow: #d9982c;
$theme-gold: #fdbb30;
$theme-red: #e31837;

$theme-default: $theme-medium-gray;
$theme-default-light: $theme-light-gray;
$theme-default-lightest: #e6e6e6;
$theme-default-dark: $theme-dark-gray;
$theme-primary: $theme-blue;
$theme-primary-dark: #043965;
$theme-primary-light: #097fe0;
$theme-primary-lightest: #dbe9f7;
$theme-secondary: $theme-gold;
$theme-secondary-dark: #de7a28;
$theme-success: $theme-green;
$theme-success-dark: #106d16;
$theme-danger: $theme-red;
$theme-danger-dark: #b5132c;

// elements
$hero-bg: $theme-yellow;
$primary-nav-bg-hover: $theme-primary-lightest;
$primary-nav-bg-active: $white;
$primary-nav-text: $theme-primary-dark;
$primary-nav-text-active: $theme-default-dark;
$content-box-border: $theme-default;
$logged-in-dashboard: $theme-danger-dark;

$lottery-blue: $theme-primary-light;

// utilities
$border-radius-sm: 10px;
$border-radius-md: 25px;
$border-radius-lg: 40px;

// Gauge Colors
$gauge-0-color: #EC2127;
$gauge-1-color: #FFCC00;
$gauge-2-color: #6484C3;
$gauge-3-color: #026A02;
$gauge-4-color: #01326F;
$gauge-5-color: #D02032;


