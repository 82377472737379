// general styles
body {
    @extend %theme-font-standard;
    background-color: $white;

    strong {
        @extend %theme-font-standard-bold;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend %theme-font-alternate-bold;
    }
}

nav {
    display: block;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

ul ul {
    margin-bottom: 0;
}

a {
    color: $black;
    text-decoration: underline;
    background-color: transparent;
}

a:hover {
    color: $theme-primary-dark;
    text-decoration: underline;
}

img {
    vertical-align: middle;
    border-style: none;
}

input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

input {
    overflow: visible;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .content-root.home .hero-carousel {
        margin-bottom: -7%;
    }
}

// To override grid.scss in build
.content-root .container {
    @media (min-width: 576px) {
        max-width: 576px;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) {
        max-width: 1280px;
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }
    .d-md-block {
        display: block !important;
    }
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

@media (min-width: 768px) {
    .mb-md-1 {
        margin-bottom: 0.25rem !important;
    }
}

@media print {
    *,
    *::before,
    *::after {
        text-shadow: none !important;
        box-shadow: none !important;
    }
    a:not(.btn) {
        text-decoration: underline;
    }
    img {
        page-break-inside: avoid;
    }
    .container {
        min-width: 992px !important;
    }
}

[class^='icon-'] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
    content: '\e902';
}

.icon-instagram:before {
    content: '\e903';
}

.icon-linkedin:before {
    content: '\e905';
}

.icon-search:before {
    content: '\e907';
}

.icon-twitter:before {
    content: '\e908';
}

.icon-youtube:before {
    content: '\e909';
}

@media (max-width: 575px) {
    .social-networks li a span {
        font-size: 45px !important;
    }
}

a {
    outline: 0;
    transition: color 0.25s ease-in-out, background 0.25s ease-in-out, opacity 0.25s ease-in-out;
}

a:hover,
a:focus {
    outline: 0;
    text-decoration: none;
}

@media (min-width: 768px) {
    .nav-drop {
        padding: 0;
        position: static;
        background: none;
        transition: none;
        border: 0;
    }

    .header-search-form {
        max-width: 240px;
        width: auto;
        margin: 0;
    }
    .header-search-form .form-group {
        margin: 0 0 0 8px;
        width: 197px;
        padding: 0;
    }
}

.btn-submit {
    color: #fff;
    background-color: $theme-dark-gray;
}

::placeholder {
    color: #808080 !important;
}

::-moz-placeholder {
    color: #808080 !important;
}

::-webkit-input-placeholder {
    color: #808080 !important;
}

:-ms-input-placeholder {
    color: #808080 !important;
}

:-moz-placeholder {
    color: #808080 !important;
}

// breadcrumbs
.breadcrumb {
    @extend .p-0;
    background-color: transparent;
    border: none;
}

// themed divider
.step-divider {
    @extend .my-3;
    background-color: $theme-default;
    height: 2px;
    width: 90px;
}

// site header
.page-header {
    header {
        .header-lottery-logo-container {
            a {
                @extend .d-block;
            }
        }
    }
}

// site footer
.page-footer {
    @extend .py-3;

    background-color: $theme-primary-lightest;
    font-size: 1.2rem;
    line-height: 1em;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .lottery-logo-link-container {
        a {
            @extend .d-block;
            @extend .text-md-left;

            img {
                @include media-breakpoint-up(xs) {
                    max-width: 50%;
                }

                @include media-breakpoint-up(md) {
                    max-width: 75%;
                }
            }
        }
    }

    #footer-social-nav {
        @extend .d-flex;
        @extend .justify-content-center;
        @extend .justify-content-md-end;
        @extend .mt-3;
        @extend .mb-1;

        font-size: 2.8em;

        a {
            color: $theme-primary-dark;
            padding: 0.5rem;
        }
    }

    #footer-main-nav {
        .nav {
            @extend %theme-font-alternate;
            @extend .d-flex;
            @extend .flex-column;
            @extend .flex-md-row;
            @extend .justify-content-center;
            @extend .justify-content-md-end;
            @extend .font-italic;

            .nav-link {
                color: $theme-dark-blue;
                text-decoration: none;
                line-height: 10px;
            }

            @include media-breakpoint-up(md) {
                .nav-item {
                    + :before {
                        content: '|';
                        display: inline-block;
                        margin-left: 0.3em;
                        color: $theme-dark-blue;
                    }
                }

                .nav-link {
                    display: inline-block;
                    padding-left: 0;

                    @include media-breakpoint-up(md) {
                        padding-left: 0.3em;
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    #footer-secondary-nav {
        @extend %theme-font-standard;

        .nav {
            @extend .d-flex;
            @extend .flex-column;
            @extend .flex-md-row;
            @extend .justify-content-center;
            @extend .justify-content-md-end;
            @extend .my-3;

            .nav-link {
                color: $theme-medium-dark-gray;
                padding-left: 0;
                font-size: 14px;
                line-height: 10px;

                &:last-child {
                    @extend .pr-0;
                }
            }

            @include media-breakpoint-up(md) {
                .nav-item {
                    + :before {
                        content: '|';
                        display: inline-block;
                        margin-left: 0.3em;
                        color: $theme-default-dark;
                    }
                }

                .nav-link {
                    display: inline-block;
                    padding-left: 0.3em;
                    text-decoration: none;

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    .disclaimer {
        @extend %theme-font-alternate;
        @extend .font-italic;
        @extend .mx-auto;

        color: $theme-medium-dark-gray;
        line-height: 1.2em;
        max-width: 250px;
        font-width: 500;
        font-size: 16px;

        @include media-breakpoint-up(md) {
            margin: 0;
            max-width: none;
        }
    }
}

// themed text
.theme-text-muted {
    color: $theme-default;
}

.theme-text-default {
    color: $theme-default;
}

.theme-text-default-dark {
    color: $theme-default-dark;
}

.theme-text-primary {
    color: $theme-primary;
}

.theme-text-secondary {
    color: $theme-secondary;
}

.theme-text-success {
    color: $theme-success;
}

.theme-text-danger {
    color: $theme-danger;
    width: 100%;
}

// points header
.points-dashboard {
    @extend %theme-font-standard-bold;
    @extend .row;

    label {
        font-size: 0.9em;
    }

    .expiring {
        color: $theme-danger-dark;
    }

    .logged-out-btn {
        @extend .w-100;
        @extend .px-2;
    }

    .points-dashboard-item {
        @extend .col-6;
        @extend .col-md-3;
        @extend .text-center;

        .btn {
            background-color: $theme-default-lightest;
            font-size: 1em;
            width: 90%;
            padding: 0;
        }
    }

    .dashboard-tooltip {
        color: #2196f3;
        margin-left: 5px;
        width: 14px;
        height: 13px;
        margin-left: 5px;
        margin-bottom: 2px;
    }

    .my-account {
        .my-account-img {
            @extend .rc-all-full;
            color: #2196f3;
            width: 33px;
            height: 34px;
        }

        .account-nav {
            @extend .rc-all-md;

            box-shadow: 0 2px 2px $theme-default;
            font-size: 0.9em;
            transform: translateX(-50%);

            @include media-breakpoint-up(sm) {
                transform: translateX(-80%);
            }

            .nav-item {
                background-color: $white;
                line-height: 1em;

                &:hover {
                    background-color: $theme-primary-lightest;
                }

                &:first-child {
                    @extend .rc-top-md;
                }

                &:last-child {
                    @extend .rc-bottom-md;
                    border: 0 none;

                    .nav-link {
                        border: 0 none;
                    }
                }

                .nav-link {
                    color: $theme-blue;
                    padding: 1em;
                    text-decoration: none;
                    border-bottom: 1px solid $theme-default-lightest;
                }
            }
        }
    }
}

// themed alerts
.alert {
    @extend %theme-font-standard-bold;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .rounded-pill-md;
    font-size: 0.9em;
    line-height: 1.2em;

    ul {
        @extend .list-unstyled;
    }

    .alert-text {
        @extend .col;
        @extend .m-0;
    }

    &.alert-danger {
        background-color: transparent;
        border-color: $theme-danger;
        box-shadow: inset 0 3px 5px -4px $theme-default;
        color: $theme-danger;
    }

    &.alert-success {
        background-color: transparent;
        border-color: $theme-success;
        box-shadow: inset 0 3px 5px -4px $theme-default;
        color: $theme-success;

        &.heading-alert {
            border-radius: 0 !important;
            background: $theme-green;
            color: #fff;

            a {
                color: $white;
                text-decoration: underline;
                cursor: pointer;
            }

            .alert-heading {
                display: block;
                width: 100%;
            }
        }
    }

    svg {
        font-size: 1.2em;
    }
}

// themed content boxes
.content-box {
    @extend .rc-all-md;
    @extend .mb-4;
    @extend .shadow-sm;

    background-color: $white;

    .carousel {
        @extend .row, .justify-content-center;

        .carousel-inner {
            max-width: 375px;
        }
    }

    &.danger {
        @extend .theme-text-danger;
    }

    &.logged-in-dashboard {
        .content-box-head {
            background: $theme-danger-dark;
        }
    }

    .content-box-head {
        @extend .rc-top-md;
        @extend %theme-font-alternate;
        background-color: $theme-primary;
        font-size: 1.5rem;
        text-align: center;
        text-transform: uppercase;
    }

    &.content-is-hidden {
        .content-box-head {
            @extend .rc-bottom-md;
        }
    }

    &.theme-green .content-box-head {
        background-color: $theme-green;
        color: $white;
    }

    &.theme-purple .content-box-head {
        background-color: $theme-purple;
        color: $white;
    }

    &.theme-blue .content-box-head {
        background-color: $theme-blue;
        color: $white;
    }

    &.theme-pink .content-box-head {
        background-color: $theme-pink;
        color: $white;
    }

    &.theme-darkest-gray .content-box-head {
        background-color: $theme-darkest-gray;
        color: $white;
    }

    &.theme-red .content-box-head {
        background-color: $theme-danger;
        color: $white;
    }

    &.theme-primary .content-box-head {
        background-color: $theme-blue;
        color: $white;
    }

    .content-box-body .sweeps-list,
    .content-box-body .promotion-list,
    .content-box-body .pfd-list {
        @include media-breakpoint-up(xl) {
            margin: 0 5em;
        }
    }
}

// themed buttons
.rounded-pill {
    border-radius: $border-radius-lg;
}

.rounded-pill-md {
    border-radius: $border-radius-md !important;
}


.theme-btn
 {
    @extend %theme-font-alternate;
    @extend .rounded-pill;
    box-shadow: 0 2px 2px $theme-default;
    font-size: 1.2em;
    margin: 0 0.25em;
    padding: 0.25em 3em;
    text-decoration: none;

    .disabled {
        background-color: $theme-default;
        opacity: 1;
    }

    &.inset {
        box-shadow: inset 0 2px 2px $theme-default;
    }

    &.theme-outline {
        background-color: $white;
        color: $theme-default-dark;
        border: 2px solid $theme-primary;
        box-shadow: none;
    }

    &.theme-primary {
        background-color: $theme-primary;
        color: $white;

        &:active {
            background-color: $theme-primary-dark;
        }
    }

    &.theme-secondary {
        background-color: $theme-secondary;
        color: $theme-primary-dark;

        &:active {
            background-color: $theme-secondary-dark;
        }
    }

    &.theme-success {
        background-color: $theme-success;
        color: $white;

        &:active {
            background-color: $theme-success-dark;
        }
    }

    &.theme-danger {
        background-color: $theme-danger-dark;
        color: $white;

        &:active {
            background-color: $theme-danger-dark;
        }
    }
}

.update-email-button,
.update-password-button {
    @extend .theme-btn; // Extends the .theme-btn styles
    @extend .btn;

    background-color: $theme-secondary;
    color: $theme-primary-dark;

    &:active {
        background-color: $theme-secondary-dark;
    }
}

.theme-btn-link {
    color: $theme-primary;
}

.theme-btn-link-underline {
    color: $theme-primary;
    text-decoration: underline;
}

// themed form elements
.theme-form {
    color: $black;

    label:not(.form-check-label) {
        @extend %theme-font-alternate;
        @extend .mb-1;
        font-size: 1.4em;
    }

    .form-step {
        @extend .row;
        @extend .justify-content-center;

        .inner-step {
            @extend .col-md-10;
            @extend .col-lg-8;
        }
    }
}

.theme-input {
    @extend .rounded-pill;
    border: 1px solid $theme-default-light;
    border-radius: 25px;
    box-shadow: inset 0 3px 5px -4px $theme-default;
    padding: 0.375rem 0.6rem;
    color: $theme-primary-dark;

    @include media-breakpoint-up(sm) {
        padding: 0.375rem 0.8rem;
    }
}

.theme-select {
    @extend .theme-input;
}

.theme-textarea {
    @extend .theme-input;
    border-radius: $border-radius-md !important;
}

// themed tables
.theme-table-group-title {
    @extend %theme-font-alternate;
    @extend .text-uppercase;
    background-color: $theme-primary-dark;
    font-size: 1.2em;
    border: 0 none;
    border-radius: $border-radius-lg;
    color: $white;
    text-align: center;
    padding: 0.75rem;
    margin: 5px 0;
}

.theme-table {
    thead {
        th {
            @extend %theme-font-alternate;
            @extend .text-uppercase;
            background-color: $theme-primary-light;
            border: 0 none;
            color: $white;
            font-size: 1.2em;

            &:first-child {
                @extend .rc-tl-md;
                @extend .rc-bl-md;
            }

            &:last-child {
                @extend .rc-tr-md;
                @extend .rc-br-md;
            }
        }
    }

    tr {
        &:nth-child(even) {
            td {
                background-color: $theme-default-lightest;
            }
        }

        td {
            border: 0 none;

            &:first-child {
                @extend .rc-tl-md;
                @extend .rc-bl-md;
            }

            &:last-child {
                @extend .rc-tr-md;
                @extend .rc-br-md;
            }
        }
    }

    &.mobile {
        @extend .w-100;
        max-width: 100%;
        overflow: auto;

        tr {
            td {
                @extend .text-left;
                background-color: transparent;
                min-width: 150px;
                white-space: nowrap;

                &:nth-child(odd) {
                    background-color: $theme-default-lightest;
                    border-radius: 0;
                }

                &:first-child {
                    @extend %theme-font-alternate;
                    @extend .text-white;

                    background-color: $theme-primary-light;
                    border-radius: 0;
                    left: 0;
                    text-transform: uppercase;
                }

                + td {
                    border-left: 2px solid $white;
                }
            }

            + tr {
                border-top: 2px solid $white;
            }
        }
    }
}

// site_default
.lottie {
    margin-left: auto;
    margin-right: auto;
}

.hero {
    background: $hero-bg;
    color: $white;
    font-size: 5em;
    text-align: center;
    padding: 0.75em 0.25em;
    line-height: 0.85em;

    @media screen and (min-width: 768px) {
        min-height: 220px;
    }
}

.header-nav {
    background-color: $theme-primary;
    font-size: 0.8em;

    .navbar {
        padding: 0;
        position: relative;

        .navbar-nav {
            @include media-breakpoint-up(sm) {
                padding-right: 15em;
            }
            @include media-breakpoint-down(xs) {
                margin-right: 157px;
            }

            .nav-link {
                @extend %theme-font-standard-bold;
                @extend .py-1;
                color: $white;
                text-decoration: none;
            }
        }

        .play-responsibly {
            @extend .bg-white;
            @extend .mx-auto;
            @extend .px-2;
            @extend .py-1;

            border: 4px solid $theme-primary;
            border-radius: 0 0 15px 15px;
            border-top: 0 none;
            position: absolute;
            right: 1.2em;
            width: 141px;

            @include media-breakpoint-up(sm) {
                width: 170px;
            }

            img {
                max-width: 100%;
                padding-top: 1em;
                padding-bottom: 0.2em;
            }
        }
    }

    &.logged-in {
        background-color: $theme-danger-dark;

        .play-responsibly {
            border-color: $theme-danger;
        }
    }
}

.nav-primary {
    .play-responsibly {
        @extend .w-100;
        @extend .text-center;
        @extend .mt-3;

        img {
            @extend .mx-auto;
            width: 190px;
        }
    }

    .navbar-brand {
        img {
            max-width: 210px;

            @include media-breakpoint-up(sm) {
                max-width: 260px;
            }
        }
    }

    .navbar-nav {
        @extend .ml-auto;

        li.nav-item {
            .nav-link {
                @extend %theme-font-alternate;

                color: $primary-nav-text;
                font-size: 20px;
                padding: 0.6rem 1.2rem;
                text-decoration: none;

                @include media-breakpoint-up(xl) {
                    padding: 1rem 0.5rem;
                }

                &:hover,
                &.active {
                    background-color: $primary-nav-bg-active;
                    color: $primary-nav-text-active;
                    text-decoration: underline;
                    transition: none;
                }

                .has-dropdown.active {
                    text-decoration: underline;

                    .nav-link {
                        text-decoration: none;
                    }
                }
            }

            &:last-child {
                .nav-link {
                    padding-right: 0;
                }
            }
        }

        .dropdown:hover > .dropdown-menu {
            display: block;
        }

        .dropdown {
            .dropdown-item {
                @extend %theme-font-alternate;
                @extend .px-3;

                background-color: transparent;
                color: $primary-nav-text;
                text-decoration: none;
                transition: none;
                text-align: center;
                font-size: 20px;

                @include media-breakpoint-up(xl) {
                    text-align: left;
                }

                &:hover,
                &:active {
                    background-color: transparent;
                    text-decoration: underline;
                }
            }

            .dropdown-item.active {
                text-decoration: underline;
            }
        }

        .dropdown-menu {
            @extend .rc-bottom-md;
            box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.35);
            border: 1px solid rgba(0, 0, 0, 0.15);
            margin-bottom: 1rem !important;
        }
    }
}

.toast-holder {
    position: relative;
}

.toast {
    max-width: 100%;
}

.toast-header {
    justify-content: center;
}

.toast .close {
    position: absolute;
    right: 1em;
    top: 0.7em;
}

.content-root {
    &.home {
        .navbar {
            border-radius: 0;
        }
    }

    .login-container,
    .feedback-container,
    .winners-container,
    .registration-container,
    .my-profile-container,
    .promotions-container,
    .home-container,
    .page-not-found-container {
        padding: 0 1em;
    }
}

.my-profile-container {
    .update-information-note {
        @extend .my-3;
        @extend .small;
    }

    .update-information-button {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-secondary;
    }
}

.forgot-password-container {
    @extend .mx-auto;
    @extend .col-12;
    @extend .col-md-10;
    @extend .col-lg-8;

    .resend-email-button {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-secondary;
    }
}

.login-container {
    @extend .mx-auto;
    @extend .col-12;
    @extend .col-md-10;
    @extend .col-lg-8;

    .login-submit-button {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-primary;
    }
}

// carousel
.carousel {
    min-height: 40px;

    .carousel-inner {
        margin-bottom: 2em;
    }

    .carousel-control-prev {
        @extend .ml-4;
    }

    .carousel-control-next {
        @extend .mr-4;
    }

    .carousel-control-prev,
    .carousel-control-next {
        background-color: $theme-primary;
        border-radius: 100%;
        bottom: 0.5em;
        height: 30px;
        width: 30px;
    }

    .carousel-indicators {
        @extend .flex-wrap;

        bottom: 0.3em;
        margin: 0 auto;
        max-width: 60vw;

        @include media-breakpoint-up(sm) {
            bottom: 1em;
        }

        li {
            @extend .mt-1;

            border-radius: 50%;
            border: 1px solid $theme-default-dark;
            box-shadow: inset 0 3px 6px -1px $theme-default;
            height: 14px;
            width: 14px;

            &.active {
                background-color: $theme-secondary;
                border-color: $theme-secondary;
                box-shadow: 0 2px 3px 0 $theme-default;
            }
        }
    }
}

// refer a friend
.raf-container {
    .raf-input {
        @extend .col;
        @extend .form-control;
        @extend .theme-input;
    }

    .raf-btn {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-primary;
        @extend .col-3;
        @extend .col-md-3;
        @extend .text-center;
        @extend .px-0;
    }
}

// Surveys
.raf-form {
    .content-box {
        @extend .mx-3;
    }

    .survey-submit {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-secondary;
    }

    .form-group {
        & > label {
            font-weight: bold;
        }
    }
}

// My Activity
.my-activity {
    .coupon {
        @extend .mt-0;
    }
}

// Coupons
.print-coupon-btn {
    @extend .px-4;
}

// Enter section
.home,
.enter-container,
.enter {
    .scan-tickets-btn {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-secondary;
        @extend .my-2;
    }

    .entry-box {
        @extend .mb-3;

        .enter-logged-out {
            h3 {
                color: $theme-default;
                font-size: 1.5rem;
            }
        }

        .enter-tickets-area {
            min-height: 285px;

            .content-box-body {
                padding: 0;
            }

            .nav-tabs .nav-link {
                text-align: center;
                width: 50%;

                &.active {
                    background-color: #fff;
                }
            }

            p {
                margin: 0;
            }

            .tab-content {
                padding: 1rem;

                label {
                    display: block;
                    margin-bottom: 0;
                    width: 100%;
                }

                .view-history-link {
                    font-size: 0.9em;
                }

                .ticket-number-inputs:last-of-type span {
                    display: none;
                }
            }

            .theme-input {
                padding: 0.375rem 0.5rem;
                text-align: center;
            }

            .ticket-tooltip {
                font-size: 1.2rem;
                margin-left: 5px;
                color: $lottery-blue;
                cursor: pointer;
            }

            .ticket-number-inputs:last-of-type {
                .dash {
                    display: none;
                }
            }
        }
    }
}

// enter tickets
.enter-tickets-area {
    .nav-tabs .nav-link {
        @extend %theme-font-alternate;
        background-color: $theme-default-light;
        font-size: 1.3em;
        position: relative;
        text-decoration: none;

        &.active {
            &:after {
                background-color: $theme-secondary;
                bottom: 0;
                content: '';
                display: block;
                height: 3px;
                left: 50%;
                margin-left: -25%;
                position: absolute;
                right: 50%;
                width: 50%;
            }
        }
    }

    p {
        margin-top: 0;
    }

    .tab-content {
        label {
            @extend %theme-font-alternate;
            font-size: 1.2em;
        }

        @include media-breakpoint-down(sm) {
            .theme-input {
                margin-top: 0.25rem;
            }
        }
    }

    .theme-btn {
        padding: 0.25em 3em;
    }

    #enterTicketTabs-tabpane-scratch {
        #packNum {
            min-width: 85px;
        }

        #validation {
            min-width: 165px;
        }
    }
}

// rewards & promos
.promotion-list {
    @extend .row;

    > div {
        @extend .mt-3;
    }

    > div:first-child {
        margin-top: 0;
    }
}

.promotion-card {
    @extend .rc-all-md;
    @extend .mb-3;

    border: 1px solid $theme-default;
    min-height: 560px;

    p {
        font-size: 0.9rem;
        line-height: 1.1em;
        margin: 0;
    }

    .card-img-top {
        @extend .rc-top-md;
    }

    .promotion-body {
        padding: 1rem;

        .promotion-title {
            @extend %theme-font-alternate;
            @extend .mb-2;
            @extend .text-uppercase;
            font-size: 1.4em;
        }

        .promotion-description {
            padding-top: 0;
        }
    }

    .more-info {
        @extend .theme-text-muted;
        @extend .row;
        @extend .align-items-end;
        @extend .mt-1;
        @extend .px-3;
        @extend .pb-3;

        .more-link {
            @extend .col-6;
            @extend .pt-0;
            @extend .text-right;
            right: -0.6em;

            .more-link-icon {
                font-size: 2rem;
            }
        }
    }
}

// Second Chance Promotin Details
.second-chance-promotion {
    .promotion-details {
        .rules {
            img {
                border-radius: 0;
            }
        }
    }
}

// promotion details
.promotion-details {
    margin: 0 auto;

    .row {
        @media (max-width: 991px) {
            margin: 0;
        }
    }

    img {
        @extend .rc-all-md;
    }

    .title,
    .prizes-tier {
        @extend %theme-font-alternate-bold;
        @extend .text-uppercase;
    }

    .title {
        font-size: 2em;

        @include media-breakpoint-up(md) {
            font-size: 2.4em;
        }
    }

    .prize-img-container {
        @extend .pt-4;
        border-top: 4px solid $theme-default-dark;
    }

    .prize-img {
        box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
    }

    .prizes-tier {
        @extend .mb-0;
        font-size: 1.6em;

        @include media-breakpoint-up(md) {
            font-size: 2em;
        }
    }

    .prizes-title {
        @extend %theme-font-alternate;
        font-size: 1.8em;
    }

    .prizes-description {
        font-size: 1.2em;
        line-height: 1.2em;
    }

    .content-box {
        @extend .theme-blue;
    }

    .lottery-promotion-image,
    .promotion-image-area {
        padding: 0;
        text-align: center;

        img {
            @extend .w-100;
            max-width: 396px;

            @media screen and (max-width: 1199px) {
                max-width: 600px;
                padding: 0 20px;
            }
        }

        @media screen and (max-width: 1199px) {
            padding-left: 40px;
            margin-bottom: 20px;
        }

        @media screen and (max-width: 990px) {
            padding-left: 0;
            margin: 20px auto;
        }
    }

    .lottery-promotion-description,
    .promotion-details-area {
        width: 100%;
        max-width: 775px;

        @media screen and (max-width: 1199px) {
            padding: 0 20px;
        }

        @media screen and (min-width: 992px) {
            padding-left: 40px !important;
        }

        @media screen and (max-width: 425px) {
            padding: 0rem 1.5rem;
        }
    }
}

.promotion-details {
    .gauge {
        width: 30%;
        display: inline-block;
        margin: 1.5%;
    }

    .promotion-cta {
        margin: 1em 0;
    }

    .login-message {
        color: red;
    }

    .login-message a {
        color: red;
        text-decoration: underline;
    }
}

.promotion-card {
    overflow: hidden;
}

.bar-label {
    text-align: center;
}

.star-container {
    text-align: center;
    display: block;
    margin: 1rem;
}

.gauge {
    margin: 1rem 0;

    .progress {
        border-radius: 0.5rem;
        margin-bottom: 0.3rem;
    }
}

// progress bars
.gauge_0 {
    .progress-bar {
        background-color: $gauge-0-color;
    }
}

.gauge_1 {
    .progress-bar {
        background-color: $gauge-1-color;
    }
}

.gauge_2 {
    .progress-bar {
        background-color: $gauge-2-color;
    }
}

.gauge_3 {
    .progress-bar {
        background-color: $gauge-3-color;
    }
}

.gauge_4 {
    .progress-bar {
        background-color: $gauge-4-color;
    }
}

.gauge_5 {
    .progress-bar {
        background-color: $gauge-5-color;
    }
}

// winners
.content-root.winners {
    .content-box:first-child {
        margin-top: 1em;
    }
}

// feedback
.content-root.feedback {
    .feedback-container .content-box:first-child {
        margin-top: 1em;
    }

    #feedback_form {
        label {
            @extend %theme-font-alternate;
            font-size: 1.2em;
        }
    }
}

// home
.content-root.home {
    .login-box {
        @extend .rc-bl-md;
        background: transparent linear-gradient(180deg, #0057b8 0%, #b5ceea 50%, #ffffff 100%) 0% 0% no-repeat padding-box;

        .second-chance-logo {
            img {
                @extend .my-4;
                max-width: 140px;
            }
        }

        .login-area {
            text-align: center;
            padding-bottom: 1.5rem;

            .welcome {
                .welcome-text {
                    display: block;
                    font-size: 1.3em;
                }

                .user-name {
                    font-size: 1.6em;
                    text-transform: uppercase;
                }
            }
        }
    }

    .hero-carousel {
        @extend .position-relative, .text-center;

        width: 100%;

        .carousel {
            @extend .mx-auto;

            .carousel-inner {
                @extend .mb-0;

                > * {
                    padding: 0;
                }
            }

            .carousel-item {
                float: none;

                a,
                img {
                    @extend .d-block, .w-100;
                }
            }

            .carousel-indicators {
                @extend .d-none;
            }

            .carousel-control-next,
            .carousel-control-prev {
                @extend .d-none;
            }
        }

        .hero-dashboard {
            bottom: 0;
            position: absolute;
        }
    }

    .enter-logged-out,
    .next-drawing-card {
        h3 {
            @extend %theme-font-alternate-bold;

            font-size: 1.2em;
        }

        .learn-more {
            font-size: 0.9em;
            line-height: 1.2em;
            padding: 1em 2em 1em 2em;
        }

        .line-under {
            @extend .mx-auto;
            @extend .mb-3;

            font-size: 0.9em;
            line-height: 1.2em;
            max-width: 350px;

            &:after {
                @extend .mt-3;

                background-color: $theme-secondary;
                content: '';
                display: block;
                height: 3px;
                left: 50%;
                margin-left: -15%;
                position: absolute;
                width: 30%;
            }
        }
    }
}

// log in
.content-root.login {
    .form-container {
        @extend .row;
        @extend .justify-content-center;

        #login-target {
            @extend .col-8;
            margin-top: 1em;

            .content-box {
                @extend .theme-blue;
            }

            .login-btn {
                padding: 0 5rem;
            }

            .bottom-section {
                text-align: center;
                padding: 0 0 1em 0;
            }
        }
    }
}

// register
.content-root.register {
    h3 {
        @extend %theme-font-alternate-bold;
        @extend .theme-text-default-dark;
        font-size: 1.2em;
    }

    .registration-progress-bar {
        &::before {
            border-color: $theme-default-dark;
        }
    }

    .step {
        .circle {
            @extend %theme-font-alternate;
            @extend .rc-all-full;
            border: 1px solid $theme-default;
            box-shadow: inset 0 3px 5px $theme-default;
            color: $theme-primary-dark;
        }

        &.active .circle {
            background-color: $theme-secondary;
            border-color: $theme-secondary;
            box-shadow: 0 3px 5px $theme-default;
        }
    }
}

// my-profile
.content-root.my-profile {
    .navbar {
        border-radius: 0;
    }

    .page-head-title {
        @extend .rc-bottom-md;
        font-size: 3rem;
        text-transform: uppercase;
        text-align: center;
        padding: 0.1em 0;
        color: $theme-dark-gray;
        background-color: $white;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.35);
        margin-bottom: 0.4em;
        border: 1px solid #cecece;
    }

    // Optins
    .optin-category {
        .input-group {
            margin-bottom: 1em;
        }

        //  Make Radio Buttons look like checkboxes.
        input[type='radio'] {
            appearance: none;
            border: 1px solid $theme-light-gray;
            width: 1.2em;
            height: 1.2em;
            content: none;
            outline: none;
            margin: 0;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }

        input[type='radio']:checked {
            appearance: none;
            outline: none;
            padding: 0;
            content: none;
            border: none;
        }

        input[type='radio']:checked::before {
            position: absolute;
            color: white !important;
            content: '\00A0\2713\00A0' !important;
            border: 1px solid $theme-light-gray;
            background: blue;
            font-weight: bolder;
            font-size: 0.85em;
        }
    }

    .optin-select {
        border-radius: 25px;
        color: $theme-medium-gray;
    }

    .optin-separator {
        border-top: 5px solid $theme-light-gray;
        width: 15%;
        margin: 2em 0;
    }

    .optin-category-title {
        font-size: 1.3em;
        font-weight: bold;
    }
}

// site_login
.loginbox {
    background: #fff;
    padding: 1em 0;
    margin-bottom: 1em;
}

.loginbox p {
    margin: 0;
}

// Post Claim
.content-root.post-claim {
    .congratulations-message {
        text-transform: uppercase;
    }

    .promo-image {
        border-radius: $border-radius-sm;
    }

    .claim-box {
        background-color: $white;
        border: 1px solid $theme-light-gray;
        border-radius: 1em;
        -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.07);
        box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.07);
        overflow: hidden;

        .promotion-name {
            text-transform: uppercase;
        }

        .reward-amount {
            font-size: 1.8rem;
            font-weight: 600;
        }

        .reward-currency {
            font-size: 1rem;
        }

        .sweeps-cta a {
            text-decoration: underline;
            cursor: pointer;
        }

        .theme-btn.theme-secondary {
            padding: 0.25em 2em;
        }
    }
}

// Allocatable Entries
.allocatable-entries-form {
    @extend .mb-5;

    .content-box {
        border: 0;

        .content-box-head {
            margin: 0 auto;
            width: 90%;

            @include media-breakpoint-up(sm) {
                width: 60%;
            }
        }

        .content-box-body {
            border: 1px solid $theme-light-gray;
        }
    }

    .entries-to-allocate {
        text-transform: uppercase;
    }

    .drawing-group {
        border: 1px solid $theme-light-gray;
        border-radius: $border-radius-sm;

        .number-entries-field {
            .increment {
                border-radius: 0 $border-radius-sm $border-radius-sm 0;
            }

            .decrement {
                border-radius: $border-radius-sm 0 0 $border-radius-sm;
            }

            .numbers-entry-label {
                color: $theme-light-gray;
            }
        }

        .prize-details {
            .card {
                border: none;

                .card-header {
                    background: $white;
                    border: none;
                    padding: 0;
                    font-weight: bold;
                }

                .card-body {
                    padding: 1rem 0;
                }
            }
        }
    }

    .submit-allocatable {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-secondary;
        bottom: -1.25em;
        margin: 0 auto;
        position: absolute;
        width: 90%;
        z-index: 1;

        @include media-breakpoint-up(sm) {
            left: 50%;
            margin-left: -25%;
            right: 50%;
            width: 50%;
        }
    }

    .entries-submitted-success {
        border: 1px solid $theme-success;
        border-radius: 15px;
        padding: 0.625em;
        text-align: center;
        color: $theme-success;
        font-weight: bold;
    }
}

.prizePopover {
    h3 {
        color: $theme-blue;
        font-size: 1.4rem;
    }
}

// Galaxy Fold Fix - for very small screens.
@media (max-width: 280px) {
    .galaxy-fold-open-your-device {
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        z-index: 2147483647;
    }

    .galaxy-fold-open-your-device::after {
        content: 'Please unfold your phone to access our rewards site';
        display: block;
        display: flex;
        height: 100vh;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

// loading indicator
.loading-indicator {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: $theme-primary;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    margin: 30px;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
.drawings-and-winners {
    .winners-link {
        text-transform: uppercase;
    }
}

// Winners Link Modal Styling

/* Modal custom styling */
.winners-modal .modal-content {
    border-radius: 15px; /* Rounded borders for the modal */
}

/* Header styling */
.winners-modal .modal-header {
    border-bottom: none; /* Remove the default border */
    padding: 0.5rem; /* Adjust padding as needed */
}

/* Close button styling */
.winners-modal .modal-header .close {
    font-size: 2rem; /* Bigger close icon */
    margin: -1rem -1rem -1rem auto; /* Adjust margins to position the close button */
}

/* Body styling */
.winners-modal .modal-body {
    text-align: center;

    h5 {
        margin-bottom: 2rem;
        color: #043965;
    }
    font-size: 1rem; /* Set the font size for the body text */
    color: #4f729a; /* Adjust text color as needed */
    padding: 1rem 1.5rem 1rem 1.5rem; /* Adjust body padding as needed */
}

/* Link styling */
.winners-modal .modal-body a {
    color: #007bff; /* Bootstrap primary blue by default, adjust if needed */
    text-decoration: underline; /* Underline to indicate clickable */
}

/* Footer styling */
.winners-modal .modal-footer {
    background-color: #f8f9fa; /* Light grey background */
    border-top: none; /* Remove the default border */
    text-align: center; /* Center the content */
    padding: 1.5rem; /* Adjust padding as needed */
}

/* Footer button styling */
.winners-modal .modal-footer .btn {
    background-color: #0056b3; /* Adjust to your primary color */
    color: white; /* White text color */
    border-radius: 20px; /* Rounded borders for the button */
    padding: 0.5rem 2rem; /* Adjust padding for the button */
    font-size: 1rem; /* Set the font size for the button */
    border: none; /* Remove the default border */
}

/* Adjust the button on hover */
.winners-modal .modal-footer .btn:hover {
    background-color: #004494; /* Darken the button slightly on hover */
}

.winners-modal.modal-dialog {
    max-width: 40vw;

    .modal-content {
        border: none;
        border-radius: 0;

        .mobile-winner-chunk {
            margin-bottom: 1em;
        }

        .modal-header {
            border-radius: 0;
            background-color: #fff;
            border: none;

            .modal-close-element {
                margin-left: auto;
                cursor: pointer;
            }
        }
    }

    .sweeps-table {
        caption {
            font-size: 2rem;
            color: #000;
            caption-side: top;
            text-align: left;
            line-height: 1.5em;
        }
    }
}
.current-pfd .btn {
    color: white;
    background-color: $theme-blue !important;
    padding: 6px 16px 6px 16px;
}

.pfd-list {
    .pfd-card {
        border-radius: 10px;
        background: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        font-family: Arial, sans-serif;
        max-width: 300px;
        margin: auto;
        margin-bottom: 20px;

        .btn {
            background-color: white;
            padding: 6px 16px 6px 16px;
        }
    }

    .card-image {
        position: relative;
    }

    .card-img-top {
        width: 100%;
        display: block;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .inside-image {
        @media (max-width: 320px) {
            bottom: 61%;
        }
        position: absolute; /* Not relative! Important for overlay effect */
        bottom: 48%;
        left: 0;
        width: 100%;
        background: linear-gradient(to top, #000, transparent); /* Gradient direction to top */
        color: $white; /* Text color is white */
        padding: 5px 10px;
        font-size: 0.8em;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .svg-icon {
        margin-right: 5px;
    }

    .pfd-date-icon {
        margin-right: 5px;
    }

    .pfd-point-icon {
        margin-right: 5px;
    }

    .bottom-image-date .fa-clock {
        margin-right: 5px; /* Add space between the icon and text */
    }
    .bottom-image-date {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $theme-blue;
        color: $white;
    }

    .bottom-image-date div {
        margin-right: 5px;
    }
    .card-body {
        margin-top: 5px;
        padding: 15px;
    }

    .card-points,
    .card-entries {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0;
        font-weight: bold;
    }

    .card-title {
        font-size: 1.2em;
        font-weight: bold;
        padding: 5px 0;
        margin-top: 5px;
        text-align: start;
    }

    .card-description {
        padding: 10px 0;
        font-weight: bold;
        color: #333;
    }

    .card-action {
        text-align: center;
        padding: 10px 0;
    }

    .more-link {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        margin-bottom: 20px;

        a {
            color: white;
            background: $theme-blue;
            padding: 10px 20px;
            border-radius: 20px;
            text-decoration: none;
            text-align: center;
            min-width: 15rem;
        }
    }

    .fa {
        margin-right: 5px;
    }
}
.pfd-more-link {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin-bottom: 20px;

    a {
        color: white;
        background: $theme-blue;
        padding: 5px 20px;
        border-radius: 20px;
        text-decoration: none;
        text-align: center;
        width: 20rem;

        @media (max-width: 767px) {
            max-width: 90%;
            padding: 5px 10px;
        }
    }
}

/* Reveal game modals */
#reveal-game-container {
    .modal-footer {
        text-align: center;
        display: initial;

        .footer-description {
            margin: 15px 0;
        }
    }
}

.pfd-more-link {
    margin-top: 26px;
}

// Styled checkbox for PFD and Delete Account
.pfd-container, .delete-account {
    input[type='checkbox'] {
        appearance: none;
        border: 1px solid $theme-light-gray;
        width: 1.2em;
        height: 1.2em;
        content: none;
        outline: none;
        margin: 0;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    input[type='checkbox']:checked {
        appearance: none;
        outline: none;
        padding: 0;
        content: none;
        border: none;
    }

    input[type='checkbox']:checked::before {
        position: absolute;
        color: white !important;
        content: '\00A0\2713\00A0' !important;
        border: 1px solid $theme-light-gray;
        background: blue;
        font-weight: bolder;
        font-size: 0.85em;
    }
}


// Action notifications modal

.ul-center-bullets {
    list-style-position: inside;
    padding-left: 0;
}

.action-notification {
    .modal-content {
        background: none;
        border: none;
        border-radius: none;
    }

    .action-notification-image {
        @extend .text-center;
        @extend .my-4;
    }

    .action-notification-description {
        @extend .text-center;
        @extend .my-4;
        @extend .mx-2;
    }

    .action-notification-buttons {
        @extend .text-center;
        @extend .my-2;

        a {
            text-decoration: none;
        }

        small a {
            text-decoration: underline;
        }
        .btn-primary {
            @extend .btn;
            @extend .theme-btn;
            @extend .theme-primary;
        }
    }

    .action-notification-buttons-cta {
        @extend .btn;
        @extend .btn-primary;
        @extend .mx-2;
    }

    .action-notification-alert {
        @extend .alert;
        @extend .alert-info;
        @extend .text-center;
        display: block !important;
    }

    .action-notification-alert-list {
        @extend .ul-center-bullets;
        @extend .mb-0;
    }
}

.delete-account {
    .checkbox-section {
        @extend .m-4;

        label {
            margin-left: 30px;
            font-weight: 500 !important;
        }
    }

    .submit-section {
        @extend .text-center;

        .delete-account-button {
            @extend .btn;
            @extend .btn-danger;
            @extend .mx-2;
        }
    }
}

// Terms of use page
.terms {
    #terms-of-use-content {
        overflow-y: scroll;
        overflow-x: hidden;
        -ms-overflow-x: hidden;
        height: 350px;
        padding: 20px;
        margin: 0;
    }

    .accept-terms-of-use-section{
        @extend .text-center;
        @extend .mx-auto;
        @extend .mt-3;
    }
}

.decline-terms-of-use-modal {
    .modal-content {
        background: none;
        border: none;
        border-radius: none;
    }

    .modal-content-body {
        @extend .text-center;
    }
}

.accept-tos-btn {
    @extend .btn;
    @extend .theme-btn;
    @extend .theme-secondary;
    @extend .btn-lg;
    @extend .m-2;
}

.decline-tos-btn {
    @extend .btn;
    @extend .theme-btn;
    @extend .theme-outline;
    @extend .btn-lg;
    @extend .m-2;
}