.points-dashboard {
    label {
        display: block;
        margin: 0;
    }

    .expiring {
        color: #f00;
    }

    .my-account {
        position: relative;

        .my-account-img {
            cursor: pointer;
            height: 40px;
            width: 40px;
        }

        .account-nav {
            left: 50%;
            margin-top: .5em;
            position: absolute;
            width: max-content;
            z-index: 2;

            .nav-link {
                color: #333;
                text-align: left;
            }
        }
    }
}

.dash-tooltip {
    color: #2196F3;
    margin-left: 2px;
}