.login-page {
    min-height: 500px;
    padding: 30px 0;

    .register-now {
        padding: 20px 0;
        justify-content: center;
        text-align: center;
    }

    .pw-links {
        text-align: right;
    }
}

.successStar {
    display: block;
    font-size: 6rem;
    margin: 1rem auto;
}