.carousel {
    .carousel-inner {
        display: flex;
        height: 100%;

        & > * {
            width: 100%;
            padding: 0.5em 1em;
        }
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        display: block;

        &:after {
            content: " ";
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 1;
        text-align: center;
        top: inherit;
    }

    .carousel-indicators li {
        width: 5px;
        height: 5px;
        border-radius: 50%;
    }
}